import angular from 'angular';
import 'angular-ui-bootstrap'

import AlarmModalController from './alarms/alarm.modal/alarm.modal';
import ConfirmClearDatabaseModalController from './admin/confirm.clear.database.modal/confirm.clear.database.modal';
import AddAlarmObjectController from './objects/add.object.modal/add.object.modal';
import ChooseOnMapController from './objects/choose.on.map.modal/choose.on.map.modal';
import ConfirmDeleteSubObjectController
  from './objects/confirm.delete.sub.object.modal/confirm.delete.sub.object.modal';
import EditAlarmObjectController from './objects/edit.alarm.object.modal/edit.alarm.object.modal';
import EditHintController from './objects/edit.hint.modal/edit.hint.modal';
import EditPOIController from './objects/edit.poi.modal/edit.poi.modal';
import EditRoadblockController from './objects/edit.roadblock.modal/edit.roadblock.modal';
import CreatePersonModalController from "./addressbook/create.person.modal/create.person.modal";
import EditPersonModalController from "./addressbook/edit.person.modal/edit.person.modal";
import VehicleModalController from './wache/vehicle.modal/vehicle.modal';
import ParkingModalController from './wache/parking.modal/parking.modal';
import ConfirmDeleteModalController from './misc/confirm.delete.modal/confirm.delete.modal';
import TimeplanModalController from './wache/timeplan.modal/timeplan.modal';
import CopyFromVehicleController from './wache/copy.from.vehicle.modal/copy.from.vehicle.modal';
import EditUnitModalController from './pipeline/edit.unit.modal/edit.unit.modal.ts';
import EditPluginModalController from './pipeline/edit.plugin.modal/edit.plugin.modal';
import ModalShowHelpController from './pipeline/help.modal/help.modal';
import ImportAddressbookModalController from './addressbook/import.addressbook.modal/import.addressbook.modal'
import ModalImportInstanceController from './pipeline/import.pipeline.modal/import.pipeline.modal';
import IsDirtyInstanceController from './pipeline/is.dirty.modal/is.dirty.modal';
import ModalSavePipelineTemplateInstanceController
  from './pipeline/save.pipeline.template.modal/save.pipeline.template.modal';
import ModalGetPipelineTemplatesInstanceController from './pipeline/pipeline.templates.modal/pipeline.templates.modal';
import UnitSettingsModalController from "./pipeline/unit.settings.modal/unit.settings.modal";
import EditAlarmTemplateModalController from './alarms/edit.alarm.template.modal/edit.alarm.template.modal';
import EditAlarmTemplateGridModalController
  from './alarms/edit.alarm.template.grid.modal/edit.alarm.template.grid.modal';
import ConfirmTemplateModalController from './pipeline/confirm.template.modal/confirm.template.modal';
import AddAlarmGroupModalController from './addressbook/add.alarm.group.modal/add.alarm.group.modal';
import RulesModalController from './availability/rules.modal/rules.modal';
import QrInstanceModalController from './availability/scan.qrcode.modal/scan.qrcode.modal';
import ApiModalController from './availability/edit.api.key.modal/edit.api.key.modal';
import FunctionsGroupsModalController from './availability/functions.groups.modal/functions.groups.modal';
import PersonAvailabiltyModalController from './availability/person.availability.modal/person.availability.modal';
import SingleGroupModalController from './availability/single.group.modal/single.group.modal';
import SingleSelectModalController from './availability/single.select.modal/single.select.modal';
import SettingsInstanceModalController from './misc/settings.modal/settings.modal';
import EditAm4ModalController from './addressbook/edit.AM4.modal/edit.AM4.modal';
import IOprintJobsModalController from './addressbook/ioprint.jobs.modal/ioprint.jobs.modal';
import EditAmobileModalController from './addressbook/edit.amobile.modal/edit.amobile.modal';
import SelfRegistrationModalController from './addressbook/self.registration.modal/self.registration.modal';
import CloseAlarmModalController from './alarms/close.alarm.modal/close.alarm.modal';
import ConfirmAlarmModalController from './alarms/confirm.alarm.modal/confirm.alarm.modal';
import ImportModalController from './misc/import.modal/import.modal';
import SendHelpModalController from './misc/send.help.modal';
import ConfirmPersonDeleteModalController from './misc/save.delete.modal/save.delete.modal';
import ConfirmDeleteRuleModalController from './availability/confirm.delete.rule.modal/confirm.delete.rule.modal';
import ModalImportLayerInstanceController from './layers/import.layer.modal/import.layer.modal';
import ModalShowLayerInstanceController from './layers/show.layer.modal/show.layer.modal';
import services from '../../services';
import directives from '../directives';
import AssignProvisioningModalController from './addressbook/assign.provisioning.modal/assign.provisioning.modal';
import ProvisioningInstanceModalController from './addressbook/provisioning.modal/provisioning.modal';
import CurrentEventsShiftbookModalController from './wache/currentEvents.shiftbook.modal/currentEvents.shiftbook.modal';
import CategoriesShiftbookModalController from './wache/categories.shiftbook.modal/categories.shiftbook.modal';
import VehicleFaultModalController from './wache/vehicleFault.modal/vehicleFault.modal';
import RoadblockShiftbookModalController from './wache/roadblock.shiftbook.modal/roadblock.shiftbook.modal';
import MessagesModalController from './wache/messages.modal/messages.modal';
import CurrentAlarmShiftbookModalController from './wache/currentAlarms.shiftbook.modal/currentAlarms.shiftbook.modal';
import CustomFieldsShiftbookModalController from './wache/customFields.shiftbook.modal/customFields.shiftbook.modal';
import AlarmMessagesModalController from './alarms/messages.modal/messages.modal';
import ChooseAlarmOnMapController from './alarms/choose.on.map.modal/choose.on.map.modal';
import DeleteRevokedTokensModalController from './addressbook/delete.revoked.tokens.modal/delete.revoked.tokens.modal';
import AddUnitModalController from './pipeline/add.unit.modal/add.unit.modal';
import CalendarModalController from './calendar/calendar.modal/calendar.modal';
import EventModalController from './calendar/event.modal/event.modal';
import CancelEventModalController from './calendar/cancel.event.modal/cancel.event.modal';
import EditCustomFeedbackModalController from './customFeedback/edit.custom.feedback.modal/edit.custom.feedback.modal';
import VehicleSwapModalController from './wache/swap.vehicles.modal/swap.modal';
import UserModalController from './admin/user.modal/user.modal';
import ModalProtocolAdditionalController from "./admin/protocol.additional.modal/protocol.additional.modal";
import EditAnnouncementModalController from "./wache/edit.announcement.modal/edit.announcement.modal"
import VehicleSwapHistoryModalController from "./wache/swap.history.modal/swap.history";
import AddKeywordToAAOModalController from "./wache/add.keyword.to.aao/add.keyword.to.aao";
import AAOAdjustTimeModalController from "./wache/aao.adjust.time/aao.adjust.time";
import AAOConditionsModalController from "./wache/aao.conditions/aao.conditions";
import ChangeStringModalController from "./misc/change.string.modal/change.string.modal";
import AAOVehiclesModalController from "./wache/aao.vehicles/aao.vehicles";
import RolesModalController from "./addressbook/roles/roles";
import ImportVehicleModalController from "./wache/import.vehicle.modal/import.vehicle.modal";
import ImportRoadblocksModalController from "./objects/import.roadblocks.modal/import.roadblocks.modal";
import TracelogPluginModalController from "./pipeline/tracelog.plugin.modal/tracelog.plugin.modal";
import AddVehicleController from "./wache/add.vehicle.modal/add.vehicle.modal";
import AddOrganisationModalController from "./admin/add.organisation.modal/add.organisation.modal";
import {
  SaveDeleteOrganisationModalController
} from "./admin/save.delete.organisation.modal/save.delete.organisation.modal";
import {ImportOrganisationModalController} from "./admin/import.organisation.modal/import.organisation.modal";
import AddInputModalController from "./admin/add.input.modal/add.input.modal"
import EditGeofenceModalController from "./addressbook/edit.geofence.modal/edit.geofence.modal";
import ConfirmRestartModalController from "./admin/confirm.restart.modal/confirm.restart.modal"
import SingleFeedbackGroupModalController
  from "./admin/create.edit.feedbackGroup.modal/create.edit.feedbackGroup.modal";
import EditSplitterModalController from "./admin/edit.splitter.modal/edit.splitter.modal";
import EditInputModalController from "./admin/edit.input.modal/edit.input.modal";
import ChangeNumberModalController from "./misc/change.number.modal/change.number.modal";
import AdminAddUnitModalController from "./admin/admin.add.unit.modal/admin.add.unit.modal";
import SavedeleteRolesModalController from "./addressbook/savedelete.roles.modal/savedelete.roles.modal";
import EditControllerModalController from "./admin/edit.controller.modal/edit.controller.modal";
import AddControllerModalController from "./admin/add.controller.modal/add.controller.modal";
import MissionModalController from "./alarms/mission.modal/mission.modal";
import AddPipelineProtocolModalController from "./pipeline/add.pipeline.protocol.modal/add.pipeline.protocol.modal";
import PipelineProtocolModalController from "./pipeline/pipeline.protocol.modal/pipeline.protocol.modal";
import ImportRoleModalController from "./addressbook/import.role.modal/import.role.modal";
import {ImportUnitsModalController} from "./admin/import.units.modal/import.units.modal";
import EditFe2sinkModal from "./sinks/edit.fe2sink.modal/edit.fe2sink.modal";
import EditDocumentModalController from "./objects/edit.document.modal/edit.document.modal";
import DocumentTagModalController from "./objects/document.tag.modal/document.tag.modal";

import LicenceDistributionModalController from "./admin/licence.distribution.modal/licence.distribution.modal";
import EditGeobyteModal from "./sinks/edit.geobyteSink.modal/edit.geobyteSink.modal";
import ChatDetailsModalController from "./admin/chat.details.modal/chat.details.modal";
import AAOImportModalController from "./wache/aao.import.modal/aao.import.modal";

export default angular.module('FE2.modals', ['ui.bootstrap', services, directives])
  .controller('AlarmModalController', AlarmModalController)
  .controller('AddOrganisationModalController', AddOrganisationModalController)
  .controller('AdminAddUnitModalController', AdminAddUnitModalController)
  .controller('ConfirmClearDatabaseModalController', ConfirmClearDatabaseModalController)
  .controller('SaveDeleteOrganisationModalController', SaveDeleteOrganisationModalController)
  .controller('AddAlarmObjectCtrl', AddAlarmObjectController)
  .controller('ChooseOnMapCtrl', ChooseOnMapController)
  .controller('ConfirmDeleteSubObjectCtrl', ConfirmDeleteSubObjectController)
  .controller('EditAlarmObjectCtrl', EditAlarmObjectController)
  .controller('EditHintCtrl', EditHintController)
  .controller('EditPOICtrl', EditPOIController)
  .controller('EditRoadblockCtrl', EditRoadblockController)
  .controller('CreatePersonModalController', CreatePersonModalController)
  .controller('EditPersonModalController', EditPersonModalController)
  .controller('VehicleModalController', VehicleModalController)
  .controller('ParkingModalController', ParkingModalController)
  .controller('TimeplanModalController', TimeplanModalController)
  .controller('CopyFromVehicleController', CopyFromVehicleController)
  .controller('ConfirmDeleteModalController', ConfirmDeleteModalController)
  .controller('ImportAddressbookModalController', ImportAddressbookModalController)
  .controller('EditUnitModalController', EditUnitModalController)
  .controller('EditPluginModalController', EditPluginModalController)
  .controller('ModalShowHelpController', ModalShowHelpController)
  .controller('ModalProtocolAdditionalController', ModalProtocolAdditionalController)
  .controller('ModalImportInstanceController', ModalImportInstanceController)
  .controller('IsDirtyInstanceController', IsDirtyInstanceController)
  .controller('ModalSavePipelineTemplateInstanceController', ModalSavePipelineTemplateInstanceController)
  .controller('ModalGetPipelineTemplatesInstanceController', ModalGetPipelineTemplatesInstanceController)
  .controller('EditAlarmTemplateModalController', EditAlarmTemplateModalController)
  .controller('EditAlarmTemplateGridModalController', EditAlarmTemplateGridModalController)
  .controller('ConfirmTemplateModalController', ConfirmTemplateModalController)
  .controller('ConfirmDeleteModalController', ConfirmDeleteModalController)
  .controller('ImportOrganisationModalController', ImportOrganisationModalController)
  .controller('AddAlarmGroupModalController', AddAlarmGroupModalController)
  .controller('RulesModalController', RulesModalController)
  .controller('QrInstanceModalController', QrInstanceModalController)
  .controller('ApiModalController', ApiModalController)
  .controller('FunctionsGroupsModalController', FunctionsGroupsModalController)
  .controller('PersonAvailabiltyModalController', PersonAvailabiltyModalController)
  .controller('SingleGroupModalController', SingleGroupModalController)
  .controller('SingleSelectModalController', SingleSelectModalController)
  .controller('SettingsInstanceModalController', SettingsInstanceModalController)
  .controller('EditAm4ModalController', EditAm4ModalController)
  .controller('EditAmobileModalController', EditAmobileModalController)
  .controller('AssignProvisioningModalController', AssignProvisioningModalController)
  .controller('ProvisioningInstanceModalController', ProvisioningInstanceModalController)
  .controller('SelfRegistrationModalController', SelfRegistrationModalController)
  .controller('CloseAlarmModalController', CloseAlarmModalController)
  .controller('ConfirmAlarmModalController', ConfirmAlarmModalController)
  .controller('ImportModalController', ImportModalController)
  .controller('SendHelpModalController', SendHelpModalController)
  .controller('ConfirmPersonDeleteModalController', ConfirmPersonDeleteModalController)
  .controller('ConfirmDeleteRuleModalController', ConfirmDeleteRuleModalController)
  .controller('ModalImportLayerInstanceController', ModalImportLayerInstanceController)
  .controller('ModalShowLayerInstanceController', ModalShowLayerInstanceController)
  .controller('CurrentEventsShiftbookModalController', CurrentEventsShiftbookModalController)
  .controller('CategoriesShiftbookModalController', CategoriesShiftbookModalController)
  .controller('VehicleFaultModalController', VehicleFaultModalController)
  .controller('RoadblockShiftbookModalController', RoadblockShiftbookModalController)
  .controller('MessagesModalController', MessagesModalController)
  .controller('CurrentAlarmShiftbookModalController', CurrentAlarmShiftbookModalController)
  .controller('CustomFieldsShiftbookModalController', CustomFieldsShiftbookModalController)
  .controller('AlarmMessagesModalController', AlarmMessagesModalController)
  .controller('ChooseAlarmOnMapController', ChooseAlarmOnMapController)
  .controller('DeleteRevokedTokensModalController', DeleteRevokedTokensModalController)
  .controller('AddUnitModalController', AddUnitModalController)
  .controller('CalendarModalController', CalendarModalController)
  .controller('EventModalController', EventModalController)
  .controller('CancelEventModalController', CancelEventModalController)
  .controller('EditCustomFeedbackModalController', EditCustomFeedbackModalController)
  .controller('VehicleSwapModalController', VehicleSwapModalController)
  .controller('UserModalController', UserModalController)
  .controller('EditAnnouncementModalController', EditAnnouncementModalController)
  .controller('VehicleSwapHistoryModalController', VehicleSwapHistoryModalController)
  .controller('ImportVehicleModalController', ImportVehicleModalController)
  .controller('ImportRoadblocksModalController', ImportRoadblocksModalController)
  .controller('AddVehicleController', AddVehicleController)
  .controller('AddKeywordToAAOModalController', AddKeywordToAAOModalController)
  .controller('AAOAdjustTimeModalController', AAOAdjustTimeModalController)
  .controller('AAOConditionsModalController', AAOConditionsModalController)
  .controller('ChangeStringModalController', ChangeStringModalController)
  .controller('ChangeNumberModalController', ChangeNumberModalController)
  .controller('AAOVehiclesModalController', AAOVehiclesModalController)
  .controller('RolesModalController', RolesModalController)
  .controller("UnitSettingsModalController", UnitSettingsModalController)
  .controller('TracelogPluginModalController', TracelogPluginModalController)
  .controller('AddInputModalController', AddInputModalController)
  .controller('EditGeofenceModalController', EditGeofenceModalController)
  .controller('ConfirmRestartModalController', ConfirmRestartModalController)
  .controller('SingleFeedbackGroupModalController', SingleFeedbackGroupModalController)
  .controller('EditSplitterModalController', EditSplitterModalController)
  .controller('EditInputModalController', EditInputModalController)
  .controller('SavedeleteRolesModalController', SavedeleteRolesModalController)
  .controller('EditControllerModalController', EditControllerModalController)
  .controller('AddControllerModalController', AddControllerModalController)
  .controller('MissionModalController', MissionModalController)
  .controller('AddPipelineProtocolModalController', AddPipelineProtocolModalController)
  .controller('PipelineProtocolModalController', PipelineProtocolModalController)
  .controller('ImportRoleModalController', ImportRoleModalController)
  .controller('ImportUnitsModalController', ImportUnitsModalController)
  .controller('IOprintJobsModalController', IOprintJobsModalController)
  .controller('EditFe2sinkModal', EditFe2sinkModal)
  .controller('EditGeobyteModal', EditGeobyteModal)
  .controller('EditDocumentModalController', EditDocumentModalController)
  .controller('DocumentTagModalController', DocumentTagModalController)
  .controller('LicenceDistributionModalController', LicenceDistributionModalController)
  .controller('ChatDetailsModalController', ChatDetailsModalController)
  .controller('AAOImportModalController', AAOImportModalController)
  .name;
