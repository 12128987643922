import { EVehicleStatus } from './vehicles.data';
import { AlarmData } from "./alarm.data";

export interface Queue {
    id: string;
    name: string;
    active: boolean;

    hasConnectedVehicle: boolean;
    connectedVehicleId?: string;
    status?: EVehicleStatus;
    statusColor?: string;
    statusTextColor?: string;

    // Only calculated in frontend
    empty?: boolean;
}

export interface QueueTableResponse {
    inProgress: QueueEntryResponse[];
    assigned: QueueEntryResponse[];
    done: QueueEntryResponse[];
    assignedCounter: number;
    inProgressCounter: number;
    totalCounter: number;
    doneCounter: number;
    numberOfQueues: number;

    queues: SimpleQueueResponse[];
}

export interface QueueResponse {
    inProgress: QueueEntryResponse[];
    assigned: QueueEntryResponse[];
    done: QueueEntryResponse[];

    name: string;
    assignedCounter: number;
    inProgressCounter: number;
    totalCounter: number;
    doneCounter: number;
    state: EQueueState;
    active: boolean;
    hasConnectedVehicle: boolean;
    connectedVehicleId?: string;
    status?: EVehicleStatus;
    statusColor?: string;
    statusTextColor?: string;
}

export interface QueueEntryResponse {
    id: string;
    emoji: string;
    queueId: string;
    queueActive: boolean;
    queueName: string;
    externalId: string;
    state: EQueueEntryState;
    alarmData: AlarmData;
    clock: string;
    doneClock:string;
    progressClock: string;
    priority: number;
    priorityColor: string;
    creationTime: string;
    creationTimeAsString: string;
    doneTime: string;
    doneTimeAsString: string;
    lastChangedTimeAsString: string;
    hasMap: boolean;
    similarEntries: SimpleQueueResponse[];
    similarEntriesText: string;
}

export interface SimpleQueueResponse {
    queueName: string;
    queueId: string;
}

export interface UpdateQueuePriorityRequest {
    priority: number;
}

export interface UpdateQueueActiveStateRequest {
    active: boolean;
}


export interface UpdateQueueStateRequest {
    state: EQueueEntryState;
    queueId: string;
}


export interface MoveToQueueRequest {
    newQueueId: string;
    mode: EMoveMode;
}

export enum EMoveMode {
    COPY = 'COPY', MOVE = 'MOVE'
}

export enum EQueueEntryState {
    ASSIGNED = 'ASSIGNED', IN_PROGRESS = 'IN_PROGRESS', DONE = 'DONE'
}
export enum EQueueState {
    BUSY = 'BUSY', GOOD = 'GOOD'
}

export enum EFinishMode {
    FINISH_SINGLE_ENTRY = 'FINISH_SINGLE_ENTRY', FINISH_ALL_ENTRIES = 'FINISH_ALL_ENTRIES'
  }